import * as React from 'react'
import * as t from './types'
import styled from 'styled-components'

declare global {
  interface Window {
    Tally: any;
  }
}

export default function Tally(props: t.Props) {
  // The code below will load the embed
	React.useEffect(() => {
		const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof window.Tally !== 'undefined') {
        window.Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((el) => {
          const iframe = el as HTMLIFrameElement;
          iframe.src = iframe.dataset.tallySrc ?? '';
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof window.Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.async = true;
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
	}, []);
  return (
    <Wrapper>
			<iframe
        data-tally-src={`https://tally.so/embed/${props.formID}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`}
        loading="lazy"
        width="100%"
        height="216"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
      ></iframe>
		</Wrapper>
  );
}

const Wrapper = styled.div``